import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import reactGA from "react-ga";
import { connect } from "react-redux";
import axios from "axios";
import ReactJson from "react-json-view";
import urlencode from "urlencode";
import { Dialog, DialogActions } from "@material-ui/core";

function APIs(props) {
  const apis = {
    "Operator Discovery": {
      front:
        "https://4b27werl94.execute-api.ap-south-1.amazonaws.com/latest/operator/",
      back: "8762180322",
    },
    "Operator Discovery (Forced)": {
      front: "https://d2bp5tb4v8ivi0.cloudfront.net/latest/operator/",
      back: "8090739149?forced=true",
    },
    Spectrum: {
      front: "http://ec2-13-127-218-124.ap-south-1.compute.amazonaws.com:1338/",
      back: "?device=510",
    },
    "Concox C-Series (imei)": {
      front:
        "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/status/",
      back: "?imei=8997",
    },
    "Concox C-Series (device)": {
      front:
        "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/status/",
      back: "?device=8997",
    },
    "Wired GPS": {
      front:
        "https://gxcp58pysl.execute-api.ap-south-1.amazonaws.com/latest/status",
      back: "?device=DL1LAC4623",
    },
    "Concox A-Series (imei)": {
      front:
        "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/status/",
      back: "?imei=8997",
    },
    "Concox A-Series (device)": {
      front:
        "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/status/",
      back: "?device=8997",
    },
    "GSM devices": {
      front:
        "http://ec2-13-127-218-124.ap-south-1.compute.amazonaws.com/gsm/parced/",
      back: "?limit=10&device=D1057",
    },
    "Use and Throw devices(SIM Tracking)": {
      front: "https://missed-call.intutrack.com/mc/location/",
      back: "7550238128",
    },

    // "Location API": {
    //   front: "https://missed-call.intutrack.com/mc/location/",
    //   back: "8581862011",
    // },

    "Consent API": {
      front: "https://missed-call.intutrack.com/mc/consent/",
      back: "901642846",
    },

    "Jio Consent Processing": {
      front:
        "https://jiowrapper.intusystems.info/processing/",
      back: "6300833486",
    },

    "Jio Consent Status": {
      front:
        "https://jiowrapper.intusystems.info/devices",
      back: "?tel=",
    },

    "Jio Location": {
      front:
        "https://jiowrapper.intusystems.info/location/",
      back: "6300833486",
    },

    // 'Operator Search': {
    //     front: 'https://missed-call.intutrack.com/consents/process/',
    //     // front: 'https://4b27werl94.execute-api.ap-south-1.amazonaws.com/latest/operator/',
    //     back: '8279616595?forceOperatorLookup=true'
    //     // back: '8634477267'
    // },

    "Jio Deregister": {
      front:
        "https://jiowrapper.intusystems.info/devices/deregister/",
      back: "9912065751",
      method: "POST",
    },

    "Jio Limit": {
      front:
        "https://jiowrapper.intusystems.info/devices",
      back: "?start=1500&records=500",
    },

    "Jio Response": {
      front:
        "https://jiowrapper.intusystems.info/devices",
      back: "?tel=9637882420",
    },

    "Welcome Email": {
      front:
        "https://u2wph4127g.execute-api.ap-south-1.amazonaws.com/dev/welcome/mail",
      back: "?client=Lovepreet&mailIds=lovepreet@intugine.com,niranjan@intugine.com&username=lovepreet&password=XXXX",
    },

    "EPOD Email": {
      front:
        "https://o1bvozfpe6.execute-api.ap-south-1.amazonaws.com/epod/mail",
      back: "?client=Rohit&mailIds=rohit@intugine.com",
    },

    // "IVR Location": {
    //   front: "https://supporting-services.intusystems.info/ivralt/location/",
    //   back: "7899437219",
    // },

    "IVR Consent": {
      front: "https://supporting-services.intusystems.info/ivralt/consent/",
      back: "7899437219",
    },

    // "Zumigo location API": {
    //   front:
    //     "https://d3vbpxpgze.execute-api.ap-south-1.amazonaws.com/dev/location",
    //   back: "?tel=9637882420",
    // },

    // "Zumigo Consent API": {
    //   front:
    //     "https://d3vbpxpgze.execute-api.ap-south-1.amazonaws.com/dev/consent",
    //   back: "?tel=9637882420",
    // },

    "Re-Enable Users": {
      front:
        "https://sxwersbq8j.execute-api.ap-south-1.amazonaws.com/v1/users/re_enable",
      back: "?username=rohit",
      useOpsToken: true,
    },

    "13 digit Location": {
      front: "https://supporting-services.intusystems.info/mc/location/",
      back: "5754080443066",
    },

    "13 digit Consent": {
      front: "https://supporting-services.intusystems.info/mc/consent/",
      back: "5754080443066",
    },

    "LBS to Lat-Long": {
      front:
        "https://8cwv222cy1.execute-api.ap-south-1.amazonaws.com/latest/api/v1/",
      back: "404/45/61F3/DFCC",
    },

    "Custom Notification": {
      method: "POST",
      front:
        "https://sxwersbq8j.execute-api.ap-south-1.amazonaws.com/v1/notifications/send",
      back: "/",
      body: ["title", "body", "client", "client_client", "notification_type"],
      useOpsToken: true,
    },

    // SMARTTRAIL: {
    //   front: "https://supporting-services.intusystems.info/smarttrail/location",
    //   back: "?tel=9752543435",
    // },

    SMARTTRAIL15: {
      front:
        "https://supporting-services.intusystems.info/smarttrail15/location",
      back: "?tel=9752543435",
    },

    SMARTTRAILSHORT: {
      front:
        "https://supporting-services.intusystems.info/smarttrailshort/location",
      back: "?tel=9752543435",
    },

    "Trip Restart": {
      method: "PUT",
      front: "https://sct.intutrack.com/api/prod/trips/restart/",
      back: "5fca10f3619f9b38137b351f",
      useOpsToken: true,
    },

    "JIO API": {
      method: "POST",
      front:
        "https://jiowrapper.intusystems.info/devices/activate/",
      back: "8638752042",
      body: [],
    },

    "Eway Bill": {
      front:
        "https://ewaybill.intusystems.info/api/v1/ewaybill/",
      back: "ch62@britindia.com/181971872112",
    },
    "Operator Update": {
      method: "POST",
      front: "https://sct.intutrack.com/api/test/trips/updateconsent/",
      back: "62b0797e8826b90f299a8d21?forceOperatorLookup=true",
      useOpsToken: true,
    },
    "Fastag to SBT": {
      method: "PUT",
      front: "https://sct.intutrack.com/api/test/trips",
      back: "/",
      body: ["_id", "tracking_tag"],
      headers: {
        Authorization:
          "Basic a3gyMnpJMVlrRXlXMlprWDI4VzdhTlhOaU1NRGRDbEZTaTZpNnkzcnE3dmkzRWU1SnFPQUQ4VEpwRjI5UWNVSDoxVEdHZ3phbTdNR2lNamViRWlLY2lYS1dZQm94S3duejNFQUlqbzJsWEc5cEFmWUN2SmxEQ1Ezb0cxOGNuZ0NQ",
        "Content-Type": "application/json",
      },
    },
    "New Client name in IntraCity": {
      front:
        "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-client",
      back: "?client_name=lovepreet_test_client",
    },
    Vahan: {
      front:
        "https://iz48lk6w7c.execute-api.ap-south-1.amazonaws.com/ulip/vahan",
      back: "?truckNumber=KA51AH3627",
    },
    Sarathi: {
      front:
        "https://iz48lk6w7c.execute-api.ap-south-1.amazonaws.com/ulip/sarathi",
      back: "?dlnumber=JH0220010080423&dob=1974-07-01",
    },
    Fastag: {
      front:
        "https://iz48lk6w7c.execute-api.ap-south-1.amazonaws.com/ulip/fastag",
      back: "?truckNumber=KA51AH3627",
    },
    "SMT Register Api": {
      method: "POST",
      front:
        "https://supporting-services.intusystems.info/smarttrailshort/consents/entity/add/",
      back: "7354670095",
    },
    "Switch from GPS to SIM": {
      method: "POST",
      front: "https://sct.intutrack.com/api/test/trips/trackingstart/",
      back: "",
      body: {
        noTrack: false,
      },
      useOpsToken: true,
    },
    "Utilized License": {
       front:
        "https://supporting-services.intusystems.info/smarttrailshort/licence",
      back: "",
    },
    "IPT User Enable": {
      front: "https://inplant.api.intutrack.com/api/user/enable",
      back: "?client=flipkart&username=centralhub_thv",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWQ1ZWVjNzdhMzA0NjZhMjRlNGZiY2YiLCJ1c2VybmFtZSI6ImZrdC1pbnR1LW9wcyIsIndhcmVob3VzZSI6WyJma3QtaW50dS1vcHMiXSwiY2xpZW50IjoiZmxpcGthcnQiLCJpYXQiOjE3MDg1ODUxMTF9.rm66xW5WF3rRxm3SicmN1V3fXfpzUngms1rBJ-f9rOg",
        "Content-Type": "application/json",
      },
    },
     "GPS Disable": {
      method: "PUT",
      front: "https://sct.intutrack.com/api/prod/trips/disable-gps/",
      back: "6707ab80877bea28aeaea3df",
      useOpsToken: true,
    },
  };

  const [state, setState] = React.useState({
    query: "",
    response: {},
    api: "",
    search: "",
    src: "",
    resIsJson: true,
    textfields: [],
    askPostData: false,
  });

  const trackingTags = [{
    key: "PROVIDER-CONFIG",
    value: "SIM"
  },
  {
    key: "FASTAG-1",
    value: "FASTAG"
  }]
  const logEvent = () => {
    reactGA.event({
      category: "API used",
      action: `${props.username} used ${state.api} at ${new Date().toString()}`,
    });
  };

  const search = (prop) => {
    if (!state.api) {
      return;
    }
    if (apis[state.api].method && apis[state.api].method !== "GET") {
      post();
      return;
    }
    logEvent();
    let url = apis[state.api].front + state.query;
    setState((state) => ({
      ...state,
      response: { status: "Loading" },
    }));
    if (apis[state.api].useOpsToken) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${props.token}`,
      };
    }
    else if(apis[state.api].headers){
        axios.defaults.headers.common = apis[state.api].headers;
    } else {
      axios.defaults.headers.common = {};
    }
    axios
      .get(url)
      .then((res) => {
        try {
          JSON.stringify(res.data);
          setState((state) => ({
            ...state,
            response: res.data,
            resIsJson: true,
          }));
        } catch {
          setState((state) => ({
            ...state,
            response: {
              status: "Response is not in JSON format",
            },
            resIsJson: true,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          setState((state) => ({
            ...state,
            response: {
              response: err.response.data,
            },
            resIsJson: true,
          }));
        } else {
          setState((state) => ({
            ...state,
            response: {
              status: "Unkown Error",
            },
            resIsJson: true,
          }));
        }
      });
  };

  const post = () => {
    if (!state.api) {
      return;
    }
    logEvent();
    if (apis[state.api].headers) {
      axios.defaults.headers.common = apis[state.api].headers;
    } else if (apis[state.api].useOpsToken) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${props.token}`,
      };
    } else {
      axios.defaults.headers.common = {};
    }
    let url = apis[state.api].front + state.query;
    axios[apis[state.api].method.toLowerCase()](url, state.postData)
      .then((res) => {
        try {
          JSON.stringify(res.data);
          setState((state) => ({
            ...state,
            response: res.data,
            resIsJson: true,
            askPostData: false,
          }));
        } catch {
          setState((state) => ({
            ...state,
            response: {
              status: "Response is not in JSON format",
            },
            resIsJson: true,
            askPostData: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          setState((state) => ({
            ...state,
            response: {
              response: err.response.data,
            },
            resIsJson: true,
            askPostData: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            response: {
              status: "Unkown Error",
            },
            resIsJson: true,
            askPostData: false,
          }));
        }
      });
  };

  const changeHandler = (prop, e) => {
    let value = e.target.value;
    if (prop === "api") {
      setState((state) => ({
        ...state,
        [prop]: value,
        query: apis[value].back,
        askPostData:
          (apis[value].method === "POST" || apis[value].method === "PUT") &&
          Array.isArray(apis[value].body) &&
          apis[value].body?.length,
        postData: Array.isArray(apis[value].body) ? {} : apis[value].body,
      }));
    } else {
      setState((state) => ({
        ...state,
        [prop]: value,
      }));
    }
  };

  const postChangeHandler = (prop, e) => {
    let value = e.target.value;
    console.log({ value, prop });
    setState((state) => {
      console.log({ prevState: state });
      return {
        ...state,
        postData: {
          ...state.postData,
          [prop]: value,
        },
      };
    });
  };

  const encode = () => {
    document.getElementById("urltoencode").value = urlencode(
      document.getElementById("urltoencode").value
    );
  };

  const onKeyDown = (e) => {
    if (e.keyCode == 13) {
      search("normal");
    }
  };

  return (
    <Grid
      container
      style={{ height: "100%", width: "100%", flexFlow: "column" }}
    >
      <Grid
        container
        wrap
        style={{
          justifyContent: "space-evenly",
          alignItems: "center",
          flexGrow: 0,
        }}
      >
        <Grid item style={{ margin: "15px 60px" }}>
          <TextField
            value={state.api}
            onChange={(e) => changeHandler("api", e)}
            variant="outlined"
            label="Select API"
            select
            style={{ width: "257px" }}
          >
            {Object.keys(apis)
              .sort()
              .map((api) => (
                <MenuItem value={api} key={api[api]}>
                  {api}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item style={{ flexGrow: "1", margin: "15px" }}>
          <TextField
            fullWidth
            value={state.query}
            onChange={(e) => changeHandler("query", e)}
            variant="outlined"
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item style={{ margin: "15px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => search("normal")}
          >
            Call
          </Button>
        </Grid>
        <Grid item style={{ margin: "15px" }}>
          <TextField id="urltoencode" variant="outlined" label="URL Encode" />
          <Button variant="contained" color="primary" onClick={encode}>
            Encode
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ flexGrow: 1, height: "50vh", padding: "10px" }}>
        {state.resIsJson ? (
          <ReactJson
            style={{ width: "100%", height: "100%", overflowX: "auto" }}
            src={
              typeof state.response === "string"
                ? { response: state.response }
                : state.response
            }
            theme="monokai"
          />
        ) : (
          state.response
        )}
      </Grid>
      <Dialog
        open={state.askPostData}
        onClose={() =>
          changeHandler("askPostData", { target: { value: false } })
        }
      >
        <Grid container fullWidth maxWidth="sm">
          {Array.isArray(apis[state.api]?.body)
            ? (apis[state.api]?.body || []).map((key) => (
                <Grid item xs={5} key={key}>
                  <TextField
                    label={key == 'tracking_tag'? 'Tracking Mode' : key}
                    // value={state.postData[key]}
                    onChange={(e) => postChangeHandler(key, e)}
                    variant="outlined"
                    style={{ margin: "15px 0 0 50px",width: "200px" }}
                    select={key == 'tracking_tag'? true : false}
                  >
                    {
                      key == 'tracking_tag' ? trackingTags.map(m=> (<MenuItem value={m.key} key={m.key}>
                        {m.value}
                      </MenuItem>)) : ''
                    }
                    </TextField>
                </Grid>
              ))
            : null}
        </Grid>

        {/* ///////////////////// */}
        <DialogActions>
          <Button
            variant="contained"
            onClick={() =>
              changeHandler("askPostData", { target: { value: false } })
            }
          >
            Close
          </Button>
          <Button variant="contained" onClick={post}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  username: state.username,
  token: state.token,
});

export default connect(mapStateToProps)(APIs);
