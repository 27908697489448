import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./components/login";
import Home from "./components/home";
import "./App.css";
import axios from "axios";
import actions from "./store/actions";
import reactGA from "react-ga";

function App(props) {
  const history = useHistory();

  const fullAcess = [
    // "akshayrana@intugine.com",
    // "mandar@intugine.com",
    // "zevik@intugine.com", // Left the company
    // "yashu@intugine.com",
    "niranjan@intugine.com",
    // "vikas@intugine.com",  // Left the company
    "keshav@intugine.com",
    "ayush@intugine.com",
    // "rohit@intugine.com",
    "kunal@intugine.com",
    "mayankmittal@intugine.com",
    // 'mahim@intugine.com',
    // "gokul@intugine.com",
    // "harshitha@intugine.com",
    // "fahad@intugine.com",
    "lovepreet@intugine.com",
    // 'sandeep@intugine.com',
    // "srinath@intugine.com",
    "akhil@intugine.com",
    // "ayub@intugine.com",  // Left the company
    "kunal@intugine.com",
    "pratyushaudichya@intugine.com",
    "anubhavchaturvedi@intugine.com",
    "vaibhav@intugine.com",
    // "kunalpant@intugine.com",
    // "lakhandhanani@intugine.com",
    // "adityasingh@intugine.com",
    "abhisheksharma@intugine.com",
    "shivareji@intugine.com",
    "aniketshaw@intugine.com",
    "vikalpmohnani@intugine.com",
    // "nikhilsati@intugine.com",
    "amrita@intugine.com",
    "prasurjya@intugine.com",
    "waquar@intugine.com",
    // "gajanand@intugine.com",
    "pulkitsrivastava@intugine.com",
    // "shawn@intugine.com",
    "anubhavnirwal@intugine.com",
    "nitieshkumar@intugine.com",
    "kaminipathak@intugine.com",
    "ravirajyadav@intugine.com",
    "hatimsunelwala@intugine.com",
    "kunalkumar@intugine.com",
    // "nishithirani@intugine.com",
    "hassan@intugine.com",
    "rsaundatikar@intugine.com",
    "adithyaumashankar@intugine.com",
    "krishaanusyal@intugine.com",
    "ashishajgaonkar@intugine.com",
    "pratik@intugine.com",
    "sunder@intugine.com",
    "nikitapardeshi@intugine.com",
    "radhika@intugine.com",
    "sudhanshukumar@intugine.com",
    "diksha@intugine.com"
  ];

  const apps = {
    "Tickets V2": `https://development.d2jq2wvhkq6025.amplifyapp.com/?token=${props.token}&email=${props.email}`,
    // 'Tickets V2': 'https://development.d2jq2wvhkq6025.amplifyapp.com/?token=' + props.token,
    // Tickets: `https://master.d5ikz781z2rxb.amplifyapp.com/auth/token?token=${props.token}&email=${props.email}`,
    // Tickets: 'https://master.d5ikz781z2rxb.amplifyapp.com/auth/token?token=' + props.token,
    Inspect: `https://d31hfepe47varr.cloudfront.net/?email=${props.email}`,
    // Inspect: 'https://d31hfepe47varr.cloudfront.net/',
    "Ops View": `https://master.d8ubw6164160y.amplifyapp.com/?email=${props.email}`,
    // 'Ops View': 'https://master.d8ubw6164160y.amplifyapp.com/',
    Device: `https://devicebank.intusystems.info/?user=${props.username}&email=${props.email}`,
    // Device: 'https://devicebank.intusystems.info/?user=' + props.username,
    "User Mgmgt": `https://sct-user-mgmt.intusystems.info/?user=${props.username}&email=${props.email}`,
    "Control Tower": `https://control-tower.intutrack.com/?email=${props.email}`,
    "Ops Monitor Dashboard": `https://sct.intutrack.com/#!/home?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoib3BzX21vbml0b3IiLCJjbGllbnQiOiJJbnR1Z2luZSBPUFMiLCJjbGllbnRfY2xpZW50IjpudWxsLCJwcml2aWxlZGdlIjoiY2xpZW50Iiwic2NvcGUiOiJDcmVkZW50aWFscyIsImlhdCI6MTU4OTI4MDEyMn0.s7-f-jbfQIyhr0tsH2ztm_2dbDYzMHqvXwaEnfR6FgU&email=${props.email}`,
    "Login Creation": `https://master.d3tt8hcsahe9l2.amplifyapp.com/?token=${props.token}&email=${props.email}`,
    "Delete Pings": `https://d27rqnjnz83sh2.cloudfront.net/?user=${props.username}&email=${props.email}`,
    "IntuDB": `https://master.dtq956dj9f7s4.amplifyapp.com/iFrame/home`,
    "Add Flipkart Device": "",
    "SSO Creation": "",
    // 'UpTime Monitor': 'https://stats.uptimerobot.com/GgngYhJE0B',
    APIS: "",
    PARCEL: "",
  };
  const specialApps = {
    // 'Tickets V2': 'https://development.d2jq2wvhkq6025.amplifyapp.com/?token=' + props.token
  };
  const excel = {
    "Client Credentials":
      " https://docs.google.com/spreadsheets/d/1ejVsgN95Tbozwdmy27arjSnSt4BFCn_MrolF3wyFmKM/edit#gid=630323623",
    "Training Sheet":
      "https://docs.google.com/spreadsheets/d/1q9DX7zsFHnkahxsUoxFswpeIgXaSoX5pI4p27UsGevo/edit?ts=5d959f2b#gid=0",
    "Knowledge Centre":
      "https://docs.google.com/spreadsheets/d/1jJKhB5M4ALrKW6BMH1AYKCZXIWexrL1D8tQtvh_uSAE/edit?ts=5d2c383e#gid=1168403697",
    "Operation Support Shifts":
      "https://docs.google.com/spreadsheets/d/1XHSJuglc27JEpWNNQzx0gP9u_UQ39rZpEwI5Q73N67M/edit?ts=5f9010db#gid=447708271",
    "Implementation Resource":
      "https://docs.google.com/spreadsheets/d/1ZpFrf4_FtSrqz5_MHEQU6zISpApaX4Ayk2IlRM2nyLc/edit#gid=34161179",
  };

  const showApps = {
    "harsh@intugine.com": ["Device", "APIS"],
    "prakashmishra@intugine.com": ["Device", "APIS"],
    "aakash@intugine.com": ["Device"],
    "accounts@intugine.com": ["Device"],
    "kshitij@intugine.com": ["Device"],
    "dhairya@intugine.com": ["Control Tower", "Ops View", "Tickets"],
    "rsaundatikar@intugine.com": ["Tickets V2"],
    "anubha@intugine.com": ["Tickets V2", "Tickets"],
    "chinmayparadkar@intugine.com": ["Device", "APIS"],
    "rahulbisen@intugine.com": ["Tickets V2", "Ops View"],
    "pandurang@intugine.com": ["Tickets V2", "Ops View"],
    "ashutosh@intugine.com": ["Tickets V2", "Ops View"],
    "febin@intugine.com": ["Tickets V2", "Ops View"],
    "admin@intugine.com": ["APIS"],
    // "navneet@intugine.com": ["Tickets V2"],
    "siddhantchourasia@intugine.com": ["Tickets V2"],
    "customersuccess@intugine.com": [
      "Tickets V2",
      "Ops Monitor Dashboard",
      "Inspect",
      "APIS",
      "Device",
    ],
    "akashacharya@intugine.com": ["APIS", "Device"],
    // "gajanand@intugine.com": [
    //   "Device",
    //   "APIS",
    //   "User Mgmgt",
    //   "Login Creation",
    //   "Inspect",
    // ],
    "kashvithakur@intugine.com": [
      "Ops Monitor Dashboard",
      "Inspect",
      "User Mgmgt",
      "Login Creation",
      "Control Tower",
      "Ops View",
      "Add Flipkart Device",
      "Delete Pings",
      "SSO Creation",
    ],
    "manikandan@intugine.com": ["Control Tower"],
    //AM TEAM  - 6 Members
    "rutvij@intugine.com": ["User Mgmgt", "Ops View", "Tickets V2"],
    "ashutosh@intugine.com": ["User Mgmgt", "Ops View", "Tickets V2"],
    "sangamtirtharaj@intugine.com": ["User Mgmgt", "Ops View", "Tickets V2"],
    "aditya.agrawal@intugine.com": ["User Mgmgt", "Ops View", "Tickets V2"],
    "pandurang@intugine.com": ["User Mgmgt", "Ops View", "Tickets V2"],
    "mouryakota@intugine.com": ["User Mgmgt", "Ops View", "Tickets V2"],
  };

  const specialAccess = {
    // 'niranjan@intugine.com': ['Tickets V2'],
    // 'rohit@intugine.com': ['Tickets V2'],
    // 'anubha@intugine.com': ['Tickets V2'],
    // 'mayankmittal@intugine.com': ['Tickets V2']
  };

  const defaulAccess = ["Ops View"];

  React.useEffect(() => {
    reactGA.initialize("UA-168562633-1"); //niranjan bhaiya
    // reactGA.initialize('UA-168542280-1'); //rohit
  }, []);

  if (props.loggedIn) {
    axios
      .get(
        "https://sxwersbq8j.execute-api.ap-south-1.amazonaws.com/v1/generate_intugine_user_token?email=" +
          props.email
      )
      .then(function (res) {
        props.logIn({
          token: res.data.token,
          username: props.username,
          email: props.email,
        });
        if (window.location.pathname === "/") {
          history.push(
            "/home/" +
              (fullAcess.includes(props.email)
                ? "Tickets V2"
                : (showApps[props.email] || defaulAccess)[0])
          );
        }
      })
      .catch(function (error) {
        history.push("/login");
      });
    // if (window.location.pathname === '/') {
    //   history.push('/home/Tickets')
    // }
  } else {
    history.push("/login");
  }

  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => (
            <Login
              {...props}
              defaulAccess={defaulAccess}
              showApps={showApps}
              fullAcess={fullAcess}
            />
          )}
        ></Route>
        <Route
          path="/home"
          render={(props) => (
            <Home
              {...props}
              specialApps={specialApps}
              defaulAccess={defaulAccess}
              specialAccess={specialAccess}
              showApps={showApps}
              excel={excel}
              apps={apps}
              fullAcess={fullAcess}
            />
          )}
        ></Route>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn,
  email: state.email,
  username: state.username,
  token: state.token,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (payload) => dispatch({ type: actions.LOGIN, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
