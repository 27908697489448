import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import reactGA from "react-ga";
import { connect } from "react-redux";
import axios from "axios";
import xlsx, { utils as XLSX_Utils, read as XLSX_Read } from "xlsx";

import { Dialog, DialogActions, DialogContent } from "@material-ui/core";

const Parcel = (props) => {

  const [state, setState] = React.useState({
   askPostData: false,
   client: null,
   baseUrl: "https://api.intuparcel.com/api/v1", // prod
   header: { headers: { 'x-api-key': "j9ehs1dzf0zv6lhjijw55oa6y5dncf"}}, // prod
  //  baseUrl: "https://api-staging.intuparcel.com/api/v1", // staging
  //  header: { headers: { 'x-api-key': "j9ehs1dzf0zv6lhjijw55oa6y5dncf"}}, // staging
   clientList: [
    // {
    //     "clientId": "app",
    //     "clientName": "intuparcel admin",
    //     "clientUid": "intuparceladmin"
    // },
       
    ]
  });
  const xlsxSampleDataForRetrack = [
    { header: "courierName", example: "Mr Singh Courier" },
    { header: "trackingId", example: "000000001" }
  ];

  const [file, setFile] = React.useState(null);
  const [bulkUploadData, setBulkUploadData] = React.useState([]);

  const getClientList = () => {
    axios.get(`${state.baseUrl}/internal/clients`, state.header).then((response)=>{
      console.log("client list response",response.data)
      changeHandler("clientList", { target: { value: response.data?.result ?? [] } }) 
    }).catch((e)=>{
      console.error(e)
    })
  }

  React.useEffect(()=>{
    getClientList()
  },[])
  React.useEffect(()=>{
    console.log("client id set",state.client)
  },[state.client])
   const convertExcelToJson = (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
          try {
            const workbook = xlsx.read(e.target.result, {
              type: "binary",
              cellDates: true,
            });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let data = [...xlsx.utils.sheet_to_json(worksheet, { raw: false })];
            let dataRaw = [...xlsx.utils.sheet_to_json(worksheet, { raw: true })];
  
            resolve(data);
          } catch (error) {
            console.log(error);
          }
        };
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  };
  const convertJsonToExcel = (fileName, data) => {
    return new Promise((resolve, reject) => {
      try {
        var worksheet = XLSX_Utils.aoa_to_sheet(data);
        var new_workbook = XLSX_Utils.book_new();
        XLSX_Utils.book_append_sheet(new_workbook, worksheet, "Sheet");
        xlsx.writeFile(new_workbook, `${fileName}.xlsx`);
        resolve();
      } catch (error) {
        reject();
      }
    });
  };
  React.useEffect(() => {
    if (file) {
      convertExcelToJson(file).then((res) => setBulkUploadData(res));
    }
  }, [file]);

  const handleDownloadSampleXlsx = () => {
    convertJsonToExcel("sampleRetrackShipments", [
      xlsxSampleDataForRetrack?.map((item) => item?.header),
      xlsxSampleDataForRetrack?.map((item) => item?.example),
    ])
  }

 const changeHandler = (prop, e) => {
    let value = e.target.value;
    // console.log("inside changeHandler",value)
     setState((state) => ({
        ...state,
        [prop]: value,
      }));
    // }
  };

  const postChangeHandler = (prop, e) => {
    let value = e.target.value;
    // console.log({ value, prop });
    setState((state) => {
      console.log({ prevState: state });
      return {
        ...state,
        postData: {
          ...state.postData,
          [prop]: value,
        },
      };
    });
  };

  const handleUpload = () => {
    
    const temp_header = { 
      ...state.header
    }
    temp_header.headers['clientId'] = state?.postData?.client
  // console.log("bulkUploadData",bulkUploadData, "upload data ",temp_header)
 
    
    axios
      .post(
        `${state.baseUrl}/shipments/retrack`,bulkUploadData, {...state.header, clientId: state?.postData?.client }
       )
      .then((res) => { 
      alert("Retrack shipment uploaded Successfully");
      postChangeHandler("client", { target: { value: null } }) 
      changeHandler("askPostData", { target: { value: false } }) 
      setBulkUploadData([])
    }).catch((e)=>{
      let message = e?.response?.data?.message ?? "Some Error"
      alert(message)
      console.error(e)
    })
  };

return (
    <div>
     <Grid container style={{
                display: "flex",
                justifyContent: "center",
                width: "100%"
               
              }}>
     <h1>Welcome to Parcel Page - { props?.username} - </h1>
     <Button
            variant="contained"
            style={{ height: "40px", width: "200px", margin: "20px"}}
            onClick={() =>
              changeHandler("askPostData", { target: { value: true } })
            }
          >
            Retrack Shipments 
          </Button>
    
      <Dialog
        open={ state.askPostData }
        onClose={() => changeHandler("askPostData", { target: { value: false } })}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ height: "500px" }}>
          <Grid
            item
            style={{
              padding: "20px",
              backgroundColor: "#e3e3e3"
            }}
          >
             <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                borderBottom: "2px solid",
                marginBottom: "10px"
              }}
            >
              <h2 style={{margin: "10px"}}>Upload Retrack Shipments</h2>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              
              <TextField
                label="Select Client"
                variant="outlined"
                placeholder="select"
                select
                onChange={(e) => postChangeHandler('client', e)}
                // onChange={(e) =>
                //   setFacilitiesForm({
                //     ...facilitiesForm,
                //     dashboard: e.target.value,
                //   })
                // }
                style={{ width: "200px" }}
              >
                {
                      state.clientList?.length ? state.clientList.map(m=> (<MenuItem value={m.clientId} key={m.clientId}>
                        {m.clientName}
                      </MenuItem>)) : ''
                    }
              </TextField>

             
              
            </Box>
            
             <Box
                style={{
                  height: "320px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <p
                  onClick={handleDownloadSampleXlsx}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  Click here to download sample Bulk Upload file
                </p>
              </Box>
            
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpload}
                disabled={!state.client && !bulkUploadData.length}
              >
                Upload
              </Button>
            </Box>
          </Grid>
        </div>
      </Dialog>
     </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  username: state.username,
  token: state.token,
});

export default connect(mapStateToProps)(Parcel);