import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Route, Link, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { connect } from 'react-redux';
import actions from '../store/actions'
import APIs from './apis';
import SCTUpdate from './updateSCT';
import Parcel from './parcel';
import reactGA from 'react-ga';
import { TextField, Tooltip } from '@material-ui/core';

var __html = require('./addFlipkartDevice.js');
var addflipkartdevice = { __html: __html };

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const Home = (props) => {
  
  if (
    props.loggedIn &&
    props.history.location.pathname !== '/home/' &&
    !props.history.location.pathname.includes('excel') &&
    !props.specialAccess?.[props.email]?.some(a => props.history.location.pathname.includes(a)) &&
    !(
      props.fullAcess.includes(props.email) 
      ? Object.keys(props.apps) 
      : (props.showApps[props.email] || props.defaulAccess)
  ).includes(props.history.location.pathname.split("/home/")[1])) {
    props.history.push('/home/' + (props.fullAcess.includes(props.email) ? 'Tickets V2' : ((props.showApps[props.email] || props.defaulAccess)[0])))
  } 

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    // selectedApp: apps
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const logEvent = (app) => {
    reactGA.event({
      category: 'App Changed',
      action: `${props.username} opened ${app} Page at ${(new Date()).toString()}`
    });
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List  style={{padding:'0px'}}>
        <ListItem button>
        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
        <ListItemText primary={props.username ? props.username.toUpperCase() : 'Username'} />
        <Tooltip title="Logout">
          <ExitToAppIcon onClick={logout}/>
        </Tooltip>
        </ListItem>
      </List>
      <Divider />
      <List>
      {
        (props.fullAcess.includes(props.email) ? Object.keys(props.apps) : (props.showApps[props.email] || props.defaulAccess)).map(app => (
          <List style={{padding:'0px'}}>
            <Link to={"/home/"+app} onClick={() => logEvent(app)} style={{textDecoration: 'none'}}>
              <ListItem button >
                <ListItemText primary={app} />
              </ListItem>
            </Link>
          </List>))
      }
      {
        props.specialAccess?.[props.email]?.map(app => (
          <List style={{padding:'0px'}}>
            <Link to={"/home/special/"+app} onClick={() => logEvent(app)} style={{textDecoration: 'none'}}>
              <ListItem button >
                <ListItemText primary={app} />
              </ListItem>
            </Link>
          </List>))
      }
      {props.fullAcess.includes(props.email) ?
      <TextField select label="Select Excel Sheet" variant="outlined" fullWidth>
        {Object.keys(props.excel).map(app => (
            <Link to={"/home/excel/"+app} style={{textDecoration: 'none'}}>
              <ListItem button >
                <ListItemText primary={app} />
              </ListItem>
            </Link>
        ))}
      </TextField> : null}
      </List>
    </div>
  );

  React.useEffect(() => {
      console.log(state)
  })

  const logout = () => {
    reactGA.event({
      category: 'Logged Out',
      action: `${props.username} logged out at ${(new Date()).toString()}`
    });
    props.logout();
  }

  return (
    <div>
        <Grid container style={{flexFlow: 'column', height: '100vh'}}>
            <Grid item style={{flexGrow: 0, position: 'fixed', background: 'white', borderRadius: '2px', margin: '5px'}}>
                <Button 
                  style={{height: '40px'}}
                  onClick={toggleDrawer('left', true)}
                ><MenuIcon /></Button>
            </Grid> 
            <Grid item style={{flexGrow: 1}}>
                <Grid container style={{height: '100%', flexFlow: 'column'}}>
                    <Switch>
                      <Route exact path='/home/apis' component={APIs} />
                      <Route exact path='/home/parcel' component={Parcel} />
                      <Route exact path='/home/SSO Creation' component={SCTUpdate} />
                      <Route exact path='/home/Add Flipkart Device' render={() => <span dangerouslySetInnerHTML={addflipkartdevice} />} />
                      <Route exact path='/home/special/:app' render={(renderProps) => {
                        return <iframe src={props.specialApps[renderProps.match.params.app]} title={renderProps.match.params.app} id={renderProps.match.params.app} style={{flexGrow: 1, border: 0}}></iframe>
                      }} />
                      <Route exact path='/home/:app' render={(renderProps) => {
                        return <iframe src={props.apps[renderProps.match.params.app]} title={renderProps.match.params.app} id={renderProps.match.params.app} style={{flexGrow: 1, border: 0}}></iframe>
                      }} />
                      <Route exact path='/home/excel/:app' render={(renderProps) => {
                        return <iframe src={props.excel[renderProps.match.params.app]} title={renderProps.match.params.app} id={renderProps.match.params.app} style={{flexGrow: 1, border: 0}}></iframe>
                      }} />
                    </Switch>
                </Grid>
            </Grid>
            <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
                {list('left')}
            </Drawer>
        </Grid>
    </div>
  );
}

const mapStateToProps = state => ({
    username: state.username,
    email: state.email,
    token: state.token,
    loggedIn: state.loggedIn,
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({type:actions.LOGOUT})
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);